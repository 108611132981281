import { useState } from "react";
import apiClient from "./api";
import { useNavigate } from "react-router-dom";

function useUser() {
  const [userData, setUserData] = useState({});
  const [hasSaved, setHasSaved] = useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      if (!hasSaved) {
        const res = await apiClient.get("api/a/users/all");
        console.log(res, "new user list")
        setUserData(res.data);
        setHasSaved(true);
      }
    } catch (err) {
      console.log(err);
      setHasSaved(true);
      if (err.response && (err.response.status === 401 || err.response.status === 500)) {
        if (window.location.pathname !== "/") {
          navigate("/");
          window.location.reload();
        }
      }
    }
  };

  const fetchUserById = async (id) => {
    try {
      const res = await apiClient.get(`api/a/users/${id}`);
      return res.data;
    } catch (err) {
      console.log(err);
      if (err.response && (err.response.status === 401 || err.response.status === 500)) {
        if (window.location.pathname !== "/") {
          navigate("/");
          window.location.reload();
        }
      }
      return null;
    }
  };


  const deleteUser = async (id) => {
    try {
      const res = await apiClient.delete(`api/a/users/${id}`);
      return res.data;
    } catch (err) {
      console.log(err);
      if (err.response && (err.response.status === 401 || err.response.status === 500)) {
        if (window.location.pathname !== "/") {
          navigate("/");
          window.location.reload();
        }
      }
      return null;
    }
  };

  const handlePremium = async (id, days) => {
    try {
      const res = await apiClient.put(`api/a/users/${id}/premium/${days}`);
      return res.data;
    } catch (err) {
      console.log(err);
      
      return null;
    }
  };


  const updatePassword = async (id, newPassword) => {
    try {
      const res = await apiClient.put(`api/a/users/pwd/${id}`, {
        password: newPassword
      });
      console.log(res.data);
      return res.data;  
      
    } catch (err) {
      console.log(err);
      return err.message;
    }
  };

  return { userData, setUserData, fetchData, fetchUserById , deleteUser , handlePremium , updatePassword };
}

export default useUser;
