import React from 'react';
import { Typography, Box, useTheme, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Header = ({ title, subtitle }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear all cookies for the current tab
        document.cookie.split(';').forEach(function(c) {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        });

        // Redirect to login page or home page
        navigate('/login'); // Adjust the path as needed
    };

    return (
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box>
                <Typography
                    variant="h2"
                    color={theme.palette.secondary[100]}
                    fontWeight="bold"
                    sx={{ mb: "5px" }}
                >
                    {title}
                </Typography>
                <Typography variant="h5" color={theme.palette.secondary[300]}>
                    {subtitle}
                </Typography>
            </Box>
            <Button variant="contained" color="primary" onClick={handleLogout}>
                Se déconnecter
            </Button>
        </Box>
    );
}

export default Header;
