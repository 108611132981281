// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import Login from './Scenes/Login';
import PointsOfSale from './Scenes/pointsOfSale';
import NotFound from './Scenes/NotFound';
import UserPage from 'Scenes/UserPage';

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?');
}

ReactDOM.render(
  <React.StrictMode>
      <Router>
        <ToastContainer position='top-right' />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/users/:id" element={<UserPage  />} /> 
          <Route path="/dashboard" element={<PointsOfSale />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
  </React.StrictMode>,
  rootElement
);
