import React, { useState , useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import apiClient from '../services/api';
import { getCookie } from '../services/cookie';
import Cookies from 'js-cookie';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LoginPage() {
  const [mailInput, setMailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const navigate = useNavigate();

  const handleMailInput = (event) => {
    setMailInput(event.target.value);
  };
  const handlePasswordInput = (event) => {
    setPasswordInput(event.target.value);
  };

  const login = () => {
    apiClient.get('/sanctum/csrf-cookie').then((response) => {
      apiClient
        .post('/login', {
          email: mailInput,
          password: passwordInput,
          _token: getCookie('kaarz_back_session'),
        })
        .then((response) => {
          console.log(response);
          if(!response.data.access){
            toast.info("You do not have pemission to access");
            return
          }
          const accessToken = response.data.access_token;
          Cookies.set('access_token', accessToken, { expires: 7 });

          toast.success('Connexion réussie!');

          if (window.location.pathname !== '/dashboard') {
            navigate('/dashboard');
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error('Identifiant ou mot de passe incorrect');
        });
    });
  };

  // Check if access token exists in cookies and redirect to dashboard if valid
  useEffect(() => {
    const accessToken = Cookies.get('access_token');

    if (accessToken) {
      navigate('/dashboard');
    }
  }, [navigate]);
  
  return (
    <div>
      <div
        className="flex fixed inset-0 z-[99] w-screen h-screen bg-white"
      >
        <div className="relative top-0 bottom-0 right-0 flex-shrink-0 hidden w-1/3 overflow-hidden bg-cover lg:block">
          <div className="absolute bottom-0 left-0 z-30 inline-flex items-end mb-4 ml-3 font-sans text-left text-xs text-gray-400 no-underline bg-transparent cursor-pointer group focus:no-underline">
            Photo par{'  '}
            <a
              className="underline ml-1"
              href="https://unsplash.com/@loganmeis?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
            >
              {' '}
              Logan Meis
            </a>
          </div>
          <div className="absolute inset-0 z-20 w-full h-full opacity-70 bg-gradient-to-t from-black"></div>
          <img src="/bg.jpg" className="z-10 object-cover w-full h-full" alt="background" />
        </div>
        <div className="relative flex flex-wrap items-center w-full h-full px-8">
          <div className="relative w-full max-w-sm mx-auto lg:mb-0">
            <div className="relative text-center">
              <div className="flex flex-col mb-6 space-y-2">
                <h1 className="text-2xl font-semibold tracking-tight">
                  Se connecter
                </h1>
                <p className="text-sm text-neutral-500">
                  Entrez votre adresse mail et le mot passe ci-dessous pour
                  accéder à votre espace Kaarz
                </p>
              </div>
              <form className="space-y-2">
                <input
                  type="text"
                  value={mailInput}
                  onChange={handleMailInput}
                  placeholder="name@example.com"
                  className="flex w-full h-10 px-3 py-2 text-sm bg-white border rounded-md border-neutral-300 ring-offset-background placeholder:text-neutral-500 focus:border-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400 disabled:cursor-not-allowed disabled:opacity-50"
                />
                <input
                  type="password"
                  value={passwordInput}
                  onChange={handlePasswordInput}
                  placeholder="Mot de passe"
                  className="flex w-full h-10 px-3 py-2 text-sm bg-white border rounded-md border-neutral-300 ring-offset-background placeholder:text-neutral-500 focus:border-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400 disabled:cursor-not-allowed disabled:opacity-50"
                />
                <button
                  onClick={login}
                  type="button"
                  className="inline-flex items-center justify-center w-full h-10 px-4 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 rounded-md bg-neutral-950 hover:bg-neutral-900 focus:ring-2 focus:ring-offset-2 focus:ring-neutral-900 focus:shadow-outline focus:outline-none"
                >
                  Se connecter
                </button>
              </form>
            </div>
            
            <p className="px-8 mt-1 text-sm text-center text-neutral-500">
              En utilsant Kaarz, vous acceptez les{' '}
              <a
                className="underline underline-offset-4 hover:text-primary"
                href="/terms"
              >
                conditions d'utilisation de la plateforme
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
