import axios from "axios";
import { getCookie } from "./cookie";
import Cookies from "js-cookie";

const accessToken = Cookies.get('access_token');


//console.log("tokeeeeeeeeeeen", accessToken)


// axios.defaults.withCredentials = true;
// axios.defaults.headers.common["Content-Type"] = "application/json";

axios.defaults.headers.common["X-CSRF-TOKEN"] = getCookie("XSRF-TOKEN");

// axios.defaults.headers.common["Authorization"] = `Bearer ${getCookie(
//   "XSRF-TOKEN"
// )}`;

const apiClient = axios.create({
  //baseURL: "http://localhost:8000",
  baseURL: "https://api.gokaarz.com",
  withCredentials: true,
  headers: {
    Authorization: "Bearer " + accessToken,
    "X-App-Identifier": "kaarz_admin_panel",
    //"X-XSRF-TOKEN": getCookie("kaarz_back_session"),
  },
});

export default apiClient;
