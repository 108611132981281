import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Button, Divider } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import useUser from 'services/users';
import moment from 'moment';

const styles = {
  header: {
    marginBottom: '1em',
  },
  section: {
    marginBottom: '1.5em',
  },
  sectionHeader: {
    marginBottom: '0.5em',
    color: '#3f51b5',
  },
  info: {
    marginBottom: '0.5em',
    padding: '0.5em 1em',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  buttonGroup: {
    marginTop: '1.5em',
    display: 'flex',
    gap: '10px',
  },
  button: {
    flexGrow: 1,
  },
};

const UserPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { fetchUserById, updateUserPremium, deleteUser, handlePremium, updatePassword } = useUser();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async (userId) => {
      try {
        const user = await fetchUserById(userId);
        setUserData(user);
        setLoading(false);

        if (!user) {
          toast.error('User not found');
        }
      } catch (error) {
        console.error(error);
        toast.error('An error occurred while fetching user data');
      }
    };

    fetchUser(id);
  }, [id]);

  const handleUpdatePassword = () => {
    const newPassword = prompt('Enter new password for user:');
    if (newPassword) {
      updatePassword(userData.id, newPassword)
        .then((res) => {
          if (res.success) {
            toast.info('Password updated successfully');
          } else {
            toast.error('Failed to update Password');
          }
        })
        .catch((error) => {
          console.error('Error updating user:', error);
          toast.error('Failed to update user');
        })
        .finally(() => {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
    } else {
      toast.info('Update password failed, please try again later');
    }
  };

  const handleDeleteUser = () => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      deleteUser(userData.id)
        .then((res) => {
          if (res.success) {
            toast.success('User deleted successfully');
          } else {
            toast.error('Failed to delete user');
          }
        })
        .catch((error) => {
          console.error('Error deleting user:', error);
        })
        .finally(() => {
          setTimeout(() => {
            navigate('/dashboard');
          }, 2000);
        });
    }
  };

  const handlePremiumAction = () => {
    if (userData.is_premium) {
      handlePremium(userData.id, 0)
        .then((res) => {
          if (res.success) {
            toast.success('User updated successfully');
          } else {
            toast.error('Failed to update user');
          }
        })
        .catch((error) => {
          console.error('Error updating user:', error);
          toast.error('Failed to update user');
        })
        .finally(() => {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
      return;
    }

    const daysInput = prompt('Enter number of days for premium status:');
    const days = parseInt(daysInput);

    if (isNaN(days)) {
      alert('Invalid input. Please enter a valid number.');
      return;
    }

    handlePremium(userData.id, days)
      .then((res) => {
        if (res.success) {
          toast.success('User updated successfully');
        } else {
          toast.error('Failed to update user');
        }
      })
      .catch((error) => {
        console.error('Error updating user:', error);
        toast.error('Failed to update user');
      })
      .finally(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!userData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <Typography variant="h5" >User not found</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ overflowY:'scroll', padding: '2em' }}>
      <ToastContainer />
      <Typography 
        variant="h4" 
        style={styles.header}
        sx={{
          
          maxWidth: '800px',
          backgroundColor: '#f4f6f8',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          

        }}
      >
        {userData.name}
      </Typography>
      <Box style={styles.buttonGroup}>
        <Button variant="contained" color="primary" onClick={handleUpdatePassword} style={styles.button}>
          Modifier le mot de passe
        </Button>
        <Button variant="contained" color="error" onClick={handleDeleteUser} style={styles.button}>
          Supprimer l'utilisateur
        </Button>
        {userData.is_premium === 1 ? (
          <Button variant="contained" color="success" onClick={handlePremiumAction} style={styles.button}>
            Résilier l'abonnement
          </Button>
        ) : (
          <Button variant="contained" color="success" onClick={handlePremiumAction} style={styles.button}>
            Passer à Premium
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',

          justifyContent: 'space-around',

        }}
      >
        <Box
          style={styles.section}
          sx={{
            padding: '2em',
            maxWidth: '100%',
            margin: '1em',
            backgroundColor: '#f4f6f8',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          

          }}
        >
          <Typography variant="h5" style={styles.sectionHeader}>Informations Utilisateur</Typography>
          <Typography variant="body1" style={styles.info}>Adresse électronique: {userData.email}</Typography>
          <Typography variant="body1" style={styles.info}>Date de vérification de l'email: {userData.email_verified_at || 'N/A'}</Typography>
          <Typography variant="body1" style={styles.info}>Date de création: {moment(userData.created_at).format('DD-MM-YYYY HH:mm:ss')}</Typography>
          <Typography variant="body1" style={styles.info}>Dernière mise à jour: {moment(userData.updated_at).format('DD-MM-YYYY HH:mm:ss')}</Typography>
          <Typography variant="body1" style={styles.info}>Date d'expiration: {moment(userData.trial_until).format('DD-MM-YYYY HH:mm:ss')}</Typography>
          <Typography variant="body1" style={styles.info}>Rang: {userData.rank}</Typography>
          <Typography variant="body1" style={styles.info}>Garage ID: {userData.garage_id}</Typography>
          <Typography variant="body1" style={styles.info}>Accès Administrateur: {userData.adminAccess ? 'Yes' : 'No'}</Typography>
          <Typography variant="body1" style={styles.info}>Premium: {userData.is_premium ? 'Yes' : 'No'}</Typography>
        </Box>
        {/*
        {userData.garage && (
          <Box
          sx={{
            display: 'flex',
            
            
            
            
          }}>
           <Box
            style={styles.section}
            sx={{
              padding: '2em',
              maxWidth: '800px',
              margin: '1em',
              backgroundColor: '#f4f6f8',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              alignSelf: 'center',
            }}
          >
            <Typography variant="h5" style={styles.sectionHeader}>Garage Details</Typography>
            <Typography variant="body1" style={styles.info}>Name: {userData.garage.name}</Typography>
            <Typography variant="body1" style={styles.info}>Email: {userData.garage.email}</Typography>
            <Typography variant="body1" style={styles.info}>Address: {userData.garage.address}</Typography>
            <Typography variant="body1" style={styles.info}>Phone: {userData.garage.phone}</Typography>
            <Typography variant="body1" style={styles.info}>Hours: {userData.garage.hours || 'N/A'}</Typography>
            <Typography variant="body1" style={styles.info}>Status: {userData.garage.status ? 'Active' : 'Inactive'}</Typography>
            <Typography variant="body1" style={styles.info}>Owner: {userData.garage.owner}</Typography>
            <Typography variant="body1" style={styles.info}>Created At: {userData.garage.created_at}</Typography>
            <Typography variant="body1" style={styles.info}>Updated At: {userData.garage.updated_at}</Typography>
          </Box> */}
          {/* <Box
            style={styles.section}
            sx={{
              padding: '2em',
              maxWidth: '800px',
              margin: '1em',
              backgroundColor: '#f4f6f8',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              alignSelf: 'center',
            }}
          >
            <Typography variant="body1" style={styles.info}>IBAN: {userData.garage.iban || 'N/A'}</Typography>
            <Typography variant="body1" style={styles.info}>BIC: {userData.garage.bic || 'N/A'}</Typography>
            <Typography variant="body1" style={styles.info}>SIREN: {userData.garage.siren || 'N/A'}</Typography>
            <Typography variant="body1" style={styles.info}>Invoice Image: {userData.garage.invoiceimg || 'N/A'}</Typography>
            <Typography variant="body1" style={styles.info}>Charges: {userData.garage.charges || 'N/A'}</Typography>
            <Typography variant="body1" style={styles.info}>Invoice Number: {userData.garage.invoice_nb}</Typography>
            <Typography variant="body1" style={styles.info}>Hourly Rates: {userData.garage.hourly_rates || 'N/A'}</Typography>
            <Typography variant="body1" style={styles.info}>ODR Number: {userData.garage.odr_nb || 'N/A'}</Typography>
            <Typography variant="body1" style={styles.info}>Quote Number: {userData.garage.quote_nb}</Typography>
          </Box> 
          </Box>
        )}
          */}
          </Box>
    </Box>
  );
};

export default UserPage;
