import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Header from "components/Header";
import moment from 'moment';
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import useUser from "../../services/users";

const PointsOfSale = () => {
  const theme = useTheme();
  const rowCount = 100;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [sort, setSort] = useState(JSON.stringify([{ field: "id", sort: "desc" }]));
  const [search, setSearch] = useState("");

  const [dataLoading, setDataLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page,
    pageSize,
    search,
    sort
  });

  const { userData, fetchData } = useUser(); // Using the useUser hook to fetch data
  const [rowCountState, setRowCountState] = useState(rowCount);

  const navigate = useNavigate(); // React Router's useNavigate hook

  console.log(userData);

  useEffect(() => {
    fetchData().then(() => setDataLoading(false));
  }, [fetchData]);

  useEffect(() => {
    if (userData && !dataLoading) {
      setRowCountState(userData.length); // Assuming userData.total represents the total number of rows
    }
  }, [search, userData, dataLoading]);

  const handleButtonClick = (id) => {
    navigate(`/users/${id}`);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
    },
    {
      field: "name",
      headerName: "Nom et Prénom",
      flex: 0.3,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.7,
    },
    {
      field: "garage",
      headerName: "Tél",
      flex: 0.5,
      valueGetter: (params) => params.row.garage ? params.row.garage.phone : '',
    },
    {
      field: "created_at",
      headerName: "Date de création de compte",
      flex: 0.6,
      renderCell: (params) => {
        const formattedDate = moment(params.value).format('DD/MM/YYYY HH:mm:ss'); // Format example: DD/MM/YYYY HH:mm:ss
        return formattedDate;
      }
    },
    {
      field: "trial_until",
      headerName: "Date d’expiration du logiciel",
      flex: 0.5,
      renderCell: (params) => {
        
        if (params.value == null) {
          return "Compte expiré";
        }
        const formattedDate = moment(params.value).format('DD/MM/YYYY'); // Format example: DD/MM/YYYY
        return formattedDate;
      }
    },
    {
      field: "rank",
      headerName: "Role",
      flex: 0.4,
    },
    {
      field: "is_premium",
      headerName: "Premium",
      flex: 0.5,
      renderCell: (params) => {
        const isPremium = params.value; // Assuming 'params.value' contains the value of 'is_premium'
    
        // Conditionally render content based on 'is_premium' value
        return isPremium === 1 ? "Payé" : "Version d'essai";
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.4,
      renderCell: (params) => (
        <button
          variant="contained"
          color="default"
          style={{ backgroundColor: "white" , color: "black" , padding :" 0.5em" , border :"1px solid #008FFB", width :"5em" , borderRadius :"25px" }}
          onClick={() => handleButtonClick(params.row.id)}
        >
          Voir
        </button>
      ),
    },
  ];

  const formatDataForExport = () => {
    return userData?.map((item) => ({
      id: item.id,
      name: item.name,
      email: item.email,
      created_at: item.created_at,
      updated_at: item.updated_at,
      trial_until: item.trial_until,
      garage_id: item.garage_id,
      rank: item.rank,
      is_premium: item.is_premium,
    })) || [];
  };

  return (
    !dataLoading && (
      <Box backgroundColor="#F3F5FA" borderRadius="20px 0px 0px 0px" p="1.5em">
        <Header title="Kaarz sas, Panneau d'administrateur" subtitle="Vous permet de voir et de gérer les utilisateurs de Kaarz.com." />
        
        <Box
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#008FFB",
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: "rgba(27, 157, 238, 0.2)",
            },
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "#FFFFFF",
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#FFFFFF",
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => row.id}
            rows={userData || []}
            columns={columns}
            rowCount={rowCountState}
            disableRowSelectionOnClick
            paginationMode="client"
            sortingMode="client"
            pageSize={paginationModel.pageSize}
            loading={dataLoading}
            onPaginationModelChange={(newModel) => {
              setDataLoading(true);
              setPaginationModel(newModel);
            }}
            onSortModelChange={(newSortModel) => {
              setPaginationModel((prev) => ({
                ...prev,
                sort: JSON.stringify(newSortModel[0]), // Assuming single column sorting
              }));
            }}
            slots={{
              toolbar: () => (
                <DataGridCustomToolbar
                  setPaginationModel={setPaginationModel}
                  search={search}
                  formatDataForExport={formatDataForExport}
                />
              ),
            }}
          />
        </Box>
      </Box>
    )
  );
};

export default PointsOfSale;
